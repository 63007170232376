@import url("https://fonts.googleapis.com/css2?family=Radio+Canada:wght@300");
body {
  background-color: #f7efef;
  min-height: 100vh;
  font-family: "Radio Canada";
  font-weight: 300;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav {
  display: flex;
  flex-direction: column;
  justify-content: right;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
  margin-top: 1em;
  width: 70%;
}
.nav .logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.nav .logo-container .logo-title {
  margin-left: 1em;
  font-size: 24pt;
  font-weight: bold;
  visibility: hidden;
}
.nav .logo-container .nav-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: left;
  width: 10em;
  height: 10em;
  color: black;
}
.nav .nav-button-group {
  display: flex;
  flex-direction: row;
}
.nav .nav-button-group .nav-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6em;
  height: 3em;
  margin-left: 1em;
  color: black;
}
.nav .nav-button-group .nav-button:link {
  text-decoration: none;
}
.nav .nav-button-group .nav-button:hover {
  cursor: pointer;
}
.nav .nav-button-group .nav-current {
  background-color: #deebf7;
  border-radius: 15px 15px 15px 15px;
}

.page {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-self: center;
}
.page .page-title {
  font-size: 24pt;
  font-style: italic;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 5%;
  align-self: start;
}
.page .title-underline {
  width: 100%;
  background-color: #2867a8;
  height: 4px;
  border-radius: 50px;
}

.content-card {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-top: 2em;
  margin-bottom: 2em;
  border-style: solid;
  border-radius: 15px;
  border-color: #2867a8;
  padding: 1em;
  justify-content: space-between;
  align-items: center;
}
.content-card .content-img {
  height: 10em;
}
.content-card .content-text {
  width: 60%;
  font-size: 14pt;
}

.calendar-container {
  background-color: #deebf7;
  padding: 1em;
  border-radius: 15px;
  margin-bottom: 3em;
}

.home-content-box {
  background-color: #deebf7;
  border-radius: 15px;
}
.home-content-box .content-box-title {
  font-size: 18pt;
  margin: 1em;
  font-weight: bold;
}
.home-content-box .content-box-title-divider {
  height: 3px;
  background-color: #2867a8;
  width: 100%;
  align-self: center;
}

.announcements-box {
  margin-right: 1em;
  width: 100%;
}

.events-box {
  width: 100%;
  margin-top: 1em;
}

.about-box {
  display: flex;
  flex-direction: column;
  margin-top: 2em;
  align-items: center;
  margin-bottom: 3em;
}

.home-preview-container {
  display: flex;
  flex-direction: column;
}
.home-preview-container .announcement-preview {
  display: flex;
  flex-direction: column;
  margin-top: 2em;
  border-left: solid;
  border-color: #2867a8;
  padding-left: 1em;
  margin: 2em;
}
.home-preview-container .announcement-preview .announcement-message {
  font-size: 14pt;
}
.home-preview-container .announcement-preview .announcement-date {
  margin-top: 0.5em;
}
.home-preview-container .calendar-event-preview {
  display: flex;
  flex-direction: row;
  margin-top: 1em;
  margin-bottom: 1em;
  align-items: center;
  margin: 2em;
}
.home-preview-container .calendar-event-preview .date-box {
  display: flex;
  flex-direction: column;
  border-style: solid;
  border-color: #2867a8;
  border-radius: 15px;
  width: fit-content;
  align-items: center;
  justify-content: center;
  font-size: 11pt;
  width: 7em;
  height: 6em;
  text-align: center;
}
.home-preview-container .calendar-event-preview .event-details-container {
  margin-left: 2em;
  width: 75%;
}
.home-preview-container .calendar-event-preview .event-details-container .event-title {
  font-weight: bold;
  font-size: 12pt;
}
.home-preview-container .calendar-event-preview .event-details-container .event-description {
  font-size: 14pt;
}
.home-preview-container .calendar-event-preview .event-details-container .event-date-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1em;
}

.contact-section-header {
  font-size: 20pt;
  margin-bottom: 0.5em;
  font-weight: bold;
}

.contact-section {
  display: flex;
  flex-direction: column;
  background-color: #deebf7;
  padding: 2em;
  border-radius: 15px;
  font-size: 16pt;
  margin-bottom: 3em;
}
.contact-section .contact-person {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.contact-section .icon {
  margin-right: 0.5em;
}
.contact-section .external-href {
  color: black;
}

.footer {
  background-color: #3c5c3f;
  display: flex;
  flex-direction: row;
  margin-top: 2em;
  width: 100%;
  height: 6em;
}

@media screen and (min-width: 900px) {
  .nav {
    flex-direction: row;
  }
  .nav .logo-container {
    flex-direction: row;
  }
  .nav .logo-container .logo-title {
    visibility: visible;
  }
  .page {
    width: 70%;
  }
  .content-card {
    flex-direction: row;
  }
  .content-card .content-img {
    height: 15em;
  }
  .content-card .content-text-right {
    border-left: solid;
  }

  .announcements-box {
    width: 50%;
  }

  .events-box {
    margin-left: 1em;
    margin-top: 0em;
    width: 50%;
  }

  .home-preview-container {
    flex-direction: row;
  }
}

