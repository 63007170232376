@import url('https://fonts.googleapis.com/css2?family=Radio+Canada:wght@300');

// option 1
// $logo-colour-blue: #2867a8;
// $logo-colour-accent-light: #deebf7;
// $logo-colour-accent-lightest: #eef5fb;
// $page-colour: #fcfcfc;

// option 2
$logo-colour-blue: #2867a8;
$logo-colour-accent-light: #deebf7;
$logo-colour-accent-lightest: #eef5fb;
$page-colour: #deebf7;
$body-colour-background: #f7efef;
// $body-colour-background: white;

body {
  background-color: $body-colour-background;
  min-height: 100vh;
  font-family: 'Radio Canada';
  font-weight: 300;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  // background-color: #e8eddf;
  // background-color: $logo-colour-accent-lightest;
  // background-color: white;
}

.nav {
  display: flex;
  flex-direction: column;
  justify-content: right;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
  margin-top: 1em;
  width: 70%;

  .logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo-title {
      margin-left: 1em;
      font-size: 24pt;
      // font-style: italic;
      font-weight: bold;
      visibility: hidden;
    }

    .nav-logo {
      display: flex;
      justify-content: center;
      align-items: center;
      justify-self: left;
      width: 10em;
      height: 10em;
      color: black;
      // border-radius: 15px;
    }
  }

  .nav-button-group {
    display: flex;
    flex-direction: row;

    .nav-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 6em;
      height: 3em;
      margin-left: 1em;
      color: black;
    }
    .nav-button:link {
      text-decoration: none;
    }
    .nav-button:hover {
      cursor: pointer;
    }

    .nav-current {
      background-color: $page-colour;
      border-radius: 15px 15px 15px 15px;
    }
  }
}

.page {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-self: center;

  .page-title {
    font-size: 24pt;
    font-style: italic;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 5%;
    align-self: start;
  }

  .title-underline {
    width: 100%;
    background-color: $logo-colour-blue;
    height: 4px;
    border-radius: 50px;
  }
}

.content-card {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-top: 2em;
  margin-bottom: 2em;
  border-style: solid;
  border-radius: 15px;
  border-color: $logo-colour-blue;
  padding: 1em;
  justify-content: space-between;
  align-items: center;

  .content-img {
    // width: 20em;
    height: 10em;
  }

  .content-text {
    width: 60%;
    font-size: 14pt;
  }
}

.calendar-container {
  background-color: $page-colour;
  padding: 1em;
  border-radius: 15px;
  margin-bottom: 3em;
}

.home-content-box {
  background-color: $page-colour;
  // padding: 1em;
  border-radius: 15px;

  .content-box-title {
    font-size: 18pt;
    margin: 1em;
    font-weight: bold;
  }

  .content-box-title-divider {
    height: 3px;
    background-color: $logo-colour-blue;
    width: 100%;
    align-self: center;
    // padding-left: 1em;
    // padding-right: 1em;
  }
}

.announcements-box {
  margin-right: 1em;
  width: 100%;
}

.events-box {
  width: 100%;
  margin-top: 1em;
}

.about-box {
  display: flex;
  flex-direction: column;
  margin-top: 2em;
  align-items: center;
  margin-bottom: 3em;
}

.home-preview-container {
  display: flex;
  flex-direction: column;

  .announcement-preview {
    display: flex;
    flex-direction: column;
    margin-top: 2em;
    // margin-bottom: 2em;
    border-left: solid;
    border-color: $logo-colour-blue;
    padding-left: 1em;
    margin: 2em;

    .announcement-message {
      font-size: 14pt;
    }

    .announcement-date {
      margin-top: 0.5em;
    }
  }

  .calendar-event-preview {
    display: flex;
    flex-direction: row;
    margin-top: 1em;
    margin-bottom: 1em;
    align-items: center;
    margin: 2em;

    .date-box {
      display: flex;
      flex-direction: column;
      border-style: solid;
      border-color: $logo-colour-blue;
      border-radius: 15px;
      width: fit-content;
      align-items: center;
      justify-content: center;
      font-size: 11pt;
      width: 7em;
      height: 6em;
      text-align: center;
    }

    .event-details-container {
      margin-left: 2em;
      width: 75%;

      .event-title {
        font-weight: bold;
        font-size: 12pt;
      }

      .event-description {
        font-size: 14pt;
      }

      .event-date-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 1em;
      }
    }
  }
}

.contact-section-header {
  font-size: 20pt;
  margin-bottom: 0.5em;
  font-weight: bold;
}
.contact-section {
  display: flex;
  flex-direction: column;
  background-color: $page-colour;
  padding: 2em;
  border-radius: 15px;
  font-size: 16pt;
  margin-bottom: 3em;

  .contact-person {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }

  .icon {
    margin-right: 0.5em;
  }

  .external-href {
    // text-decoration: none;
    color: black;
  }
}

.footer {
  background-color: #3c5c3f;
  display: flex;
  flex-direction: row;
  margin-top: 2em;
  width: 100%;
  height: 6em;
}

@media screen and (min-width: 900px) {
  .app {
  }

  .nav {
    flex-direction: row;

    .logo-container {
      flex-direction: row;

      .logo-title {
        visibility: visible;
      }

      .nav-logo {
      }
    }

    .nav-button-group {
      .nav-button {
      }
      .nav-button:link {
      }
      .nav-button:hover {
      }

      .nav-current {
      }
    }
  }

  .page {
    width: 70%;

    .page-title {
    }

    .title-underline {
    }
  }

  .content-card {
    flex-direction: row;

    .content-img {
      height: 15em;
    }

    .content-text-left {
    }

    .content-text-right {
      border-left: solid;
    }
  }

  .calendar-container {
  }

  .home-content-box {
    .content-box-title {
    }

    .content-box-title-divider {
    }
  }

  .announcements-box {
    width: 50%;
  }

  .events-box {
    margin-left: 1em;
    margin-top: 0em;
    width: 50%;
  }

  .about-box {
  }

  .home-preview-container {
    flex-direction: row;

    .announcement-preview {
      .announcement-message {
      }

      .announcement-date {
      }
    }

    .calendar-event-preview {
      .date-box {
      }

      .event-details-container {
        .event-title {
        }

        .event-description {
        }

        .event-date-container {
        }
      }
    }
  }

  .contact-section-header {
  }
  .contact-section {
    .icon {
    }

    .external-href {
    }
  }

  .footer {
  }
}
